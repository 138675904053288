import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getAllUserDocuments, getDocumentById } from "../../services/generator.services";
import GeneratorModal from "../Modal/GeneratorModal";
import TemplateGenerator from "../TemplateGenerator/TemplateGenerator";
import { useDispatch } from "react-redux";
import { disableSpinner, enableSpinner } from "../../_store";
import { formatTemplateResponse } from "../../utils/common";

export { Regenerate };

function Regenerate() {
    const [userDocuments, setUserDocuments] = useState([]);
    const [userAllDocuments, setUserAllDocuments] = useState([]);
    const [documentValues, setDocumentValues] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [docQuestions, setDocQuestions] = useState([]);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [generate, setGenerate] = useState(false);
    const showSpinner = () => dispatch(enableSpinner());
    const hideSpinner = () => dispatch(disableSpinner());
    const s3BaseUrl = 'https://ftm-organization-images.s3.amazonaws.com/';

    useEffect(() => {
        showSpinner();
        getAllUserDocuments().then(docs => {
            // setDocuments(docs);
            hideSpinner();
            setUserAllDocuments(docs);
            const uniqueDocumentsArray = [...uniqueDocuments(docs).values()];
            setUserDocuments(uniqueDocumentsArray);
            console.log({ uniqueDocumentsArray });
        }).catch(err => {
            console.log(err);
            hideSpinner();
        });
    }, []);

    const uniqueDocuments = (data) => {
        return data.reduce((acc, current) => {
            // Check if the user_document_id already exists in the map
            if (!acc.has(current.user_document_id)) {
                // If it doesn't exist, add it to the map
                acc.set(current.user_document_id, current);
            }
            return acc;
        }, new Map());
    };

    function documentEdit(document) {
        formatSelectedDoc(document);
        setSelectedDocument(document);
        showSpinner();
        getDocumentById(document?.token).then(docs => {
            const { document, questions } = docs;
            setDocQuestions(questions);
            hideSpinner();
            setShow(true);
        }).catch(err => {
            console.log(err);
            hideSpinner();
        });
    }

    const formatSelectedDoc = docx => {
        const filterResp = userAllDocuments?.filter(doc => doc.user_document_id === docx?.user_document_id);
        // const formattedFormData = formatTemplateResponse(filterResp);
        setDocumentValues(filterResp);
    };

    function generateDocument(document) {
        setSelectedDocument(document);
        const filterResp = userAllDocuments?.filter(doc => doc.user_document_id === document?.user_document_id);
        setDocumentValues({ ...formatTemplateResponse(filterResp), user_document_id: document?.user_document_id });
        setGenerate(true);
    }

    function downloadDocument(doc) {
        const docName= doc?.user_document_name;
        const url  = s3BaseUrl + docName;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', docName);
        // Append link to body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
    }

    return (

        <div className="container mt-60 table__body">
            <Breadcrumb>
                <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Regenerate</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pg-hd mb-10 col-md-12 display-flex pt-20">
                <div className="h3d col-md-9">
                    My List
                </div>
                <div className="search col-md-3">
                    <input className="search-txt" type="text" name="" placeholder="Type to search" />
                    <a className="search-btn" href="#">
                        <i className="fa fa-search"></i>
                    </a>
                </div>
            </div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    {/*<th className="text-center">Cost</th>*/}
                    <th className="text-center">Date</th>
                    <th className="text-center"> Status</th>
                    <th className="text-center"> Action</th>
                </tr>
                </thead>
                <tbody>
                {userDocuments?.map(document => <tr key={document.user_document_id}>
                    <td> {document?.name}</td>
                    <td> WORD</td>
                    {/*<td className="text-center"><strong> $128.90 </strong></td>*/}
                    <td className="text-center"> {document?.date_created}</td>
                    <td className="text-center"><p className="status delivered">Generate</p></td>
                    <td className="text-center">
                        <DropdownButton>
                            <Dropdown.Item eventKey="1" onClick={() => documentEdit(document)}>Edit</Dropdown.Item>
                            <Dropdown.Item eventKey="2"
                                           onClick={() => generateDocument(document)}>Regenerate</Dropdown.Item>
                            <Dropdown.Item eventKey="3" onClick={() => downloadDocument(document)}>Download
                               </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="4" disabled>Delete</Dropdown.Item>
                        </DropdownButton>
                    </td>
                </tr>)}
                </tbody>
            </table>
            {docQuestions?.length > 0 ?
                <GeneratorModal mShow={show} questions={docQuestions} selectedDocument={selectedDocument}
                                hideModal={setShow} documentValues={documentValues}></GeneratorModal> : null}
            <TemplateGenerator templateType={selectedDocument?.name} templateName={selectedDocument?.template_name}
                               generate={generate}
                               template={documentValues}></TemplateGenerator>
        </div>
    );
}
