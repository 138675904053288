import { createSlice } from '@reduxjs/toolkit';

// create slice

const name = 'generator';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const generatorActions = { ...slice.actions };
export const generatorReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        value: null
    }
}

function createReducers() {
    return {
        createTemplate,
        clearTemplate
    };

    // payload can be a string message ('alert message') or
    // an object ({ message: 'alert message', showAfterRedirect: true })
    function createTemplate(state, action) {
        state.value = action.payload;
    }

    function clearTemplate(state) {
        state.value = null;
    }
}
