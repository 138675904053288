import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { alertActions } from '../../_store';
import "../../styles/alert.css"
export { Alert };

function Alert() {
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useSelector(x => x.alert.value);

    useEffect(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    }, [location]);

    useEffect(() => {
      //  console.log('Location changed, clearing alert');
        dispatch(alertActions.clear());
    }, [location]);
    
    // console.log('Alert:', alert);

    
    if (!alert) {
        return null;
    } else {
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 5000)
    }

    return (
        <div className="container">
            <div className="m-3">
                <div className={`alert ${alert.type}`} style={{zIndex: 999}}>
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => dispatch(alertActions.clear())}></button>
                </div>
            </div>
        </div>
    );
}
