import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { generatorReducer } from "./generator.slice";
import spinnerReducer from "./spinnerSlice";

export * from './alert.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './spinnerSlice';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        users: usersReducer,
        generator: generatorReducer,
        spinner: spinnerReducer
    },
});