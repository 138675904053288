import React, { useEffect, useState } from "react";
import DocViewer from "react-doc-viewer";
import { useParams } from "react-router-dom";

const DocumentViewer = () => {
    const s3BaseUrl = 'https://ftm-organization-images.s3.amazonaws.com/';
    const [docs, setDocumentName] = useState([])
    const { documentName } = useParams();
    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const name = urlParams.get('documentName');
    //     setDocumentName(name);
    // }, []);

    // Construct the full S3 URL using the documentName
    // const s3Url = s3BaseUrl + documentName;
    const s3Url = `${s3BaseUrl}${documentName}?response-content-disposition=inline`;
    return (
        <div>
            <iframe src={s3Url} width="100%" height="600px" title="Document Viewer" />
        </div>
    );

    return (
        <div className="container">
            {docs?.length > 0 ? <DocViewer documents={docs} /> :  null}
        </div>
    );
}

export default DocumentViewer;