import React from "react";
import PathConstants from "./PathConstants";
import { PrivateRoute } from "../components/common";
import { Navigate } from "react-router-dom";
import { AddEdit, List, UsersLayout } from "../pages/users";
import { AccountLayout, Forget, Login, Register, ResetPassword, Welcome } from "../pages/account";
import { Home } from "../pages/home";
import TemplateGenerator from "../components/TemplateGenerator/TemplateGenerator";
import DocumentViewer from "../components/docviewer/DocumentViewer";
import { Regenerate } from "../components/regenerate";
import DynamicForm from "../components/common/DynamicForm";
import { Dashboard } from "../pages/dashboard";
import Document from "../components/document/document";

const routes = [
    {
        path: "/",
        element: <PrivateRoute />,
        children: [
            {
                path: PathConstants.HOME,
                element: <Home />
            },
            { 
                path: "/", 
                element: <Navigate to="/dashboard" /> },
            {
                path: PathConstants.USER,
                element: <UsersLayout />,
                children: [
                    {
                        path: "",
                        index: true,
                        element: <List />
                    }, {
                        path: PathConstants.ADD,
                        element: <AddEdit />
                    }, {
                        path: PathConstants.EDIT,
                        element: <AddEdit />
                    }
                ]
            },
            {
                path: "dashboard",
                element: <Dashboard />
            },
            {
                path: "templateGenerator",
                element: <TemplateGenerator />
            },
            {
                path: "regenerate",
                element: <Regenerate />
            },
            {
                path: "dynamicForm",
                element: <DynamicForm />
            },
            {
                path: 'view-document/:documentName',
                element: <DocumentViewer/>
            },
            {
                path: 'document/:documentkey',
                element: <Document/>
            },
            {
                path: "generator/*",
                element: <Home />
            },
        ]
    },
    {
        path: PathConstants.ACCOUNT,
        element: <AccountLayout />,
        children: [
            {
                path: PathConstants.LOGIN,
                element: <Login />
            }, {
                path: PathConstants.REGISTER,
                element: <Register />
            }, {
                path: PathConstants.FORGET,
                element: <Forget />
            }, {
                path: PathConstants.RESET_PASSWORD,
                element: <ResetPassword />
            }, {
                path: PathConstants.WELCOME,
                element: <Welcome />
            }
        ]
    },
    { path: "*", element: <Navigate to="/account/welcome" /> }
];

export default routes;