import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import signin from "../../assets/images/login-image.jpg";
import { alertActions, authActions } from "../../_store";

export { Forget };


function Forget() {
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        username: Yup.string().required("Password is required")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    // function onSubmit({ username }) {
    //     const response = dispatch(authActions.forget({ username }));
    // }

    function onSubmit({ username }) {
        dispatch(authActions.forget({ username })).then(response => {
            if (response.payload) {
                // console.log("RESPONSE :" + JSON.stringify(response));
                dispatch(alertActions.success({
                    message: response.payload || "Password reset link sent.",
                    showAfterRedirect: true
                }));
            } else {
                dispatch(alertActions.error({
                    message: errors.message || "An error occurred. Please try again.",
                    showAfterRedirect: true
                }));
            }
        }).catch(error => {
            dispatch(alertActions.error({
                message: error.message || "An error occurred. Please try again.",
                showAfterRedirect: true
            }));
        });
    }

    return (
        <div>
            <div className="card-container ">
                <div className="col-md-12 signin-content">
                    <div className="col-md-6">
                        <div className="signin-image">
                            <figure><img className="img-trans" src={signin} alt="sing up image" /></figure>
                            <Link to="../login" className="signup-image-link">I am already member</Link>
                        </div>
                    </div>
                    <div className="col-md-6 signin-form">
                        <h2 className="form-title mb-33">Forget Password</h2>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="lbl"> Email Address </label>
                                    <input placeholder="Enter Email" name="username"
                                           type="text" {...register("username")}
                                           className={`form-control ${errors.username ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.username?.message}</div>
                                </div>

                                <div className="mt-50">
                        <span>
                        <button disabled={isSubmitting} className="btn btn-primary">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Reset Password
                        </button>
                        </span>
                                </div>

                            </form>
                        </div>
                        <div className="mt-67 text-center">
                            <span className="mr-25 social-hd">Or sign in with</span>
                            <span className="mr-15"><i className="fa fa-google google" aria-hidden="true"></i></span>
                            <span className="mr-15"><i className="fa fa-linkedin linkedin"
                                                       aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
