import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';
// import minutlylogo from "../../assets/images/minutly-logo.png";
import { Link } from 'react-router-dom';
import nouserlogo from "../../assets/images/no-user-logo.jpg"
import Dropdown from 'react-bootstrap/Dropdown';

export { Footer };

function Footer() {

    
    return (

        <div className="">
            <div className="full-width-header md-main-header">
                <header id="rs-header" className="rs-header fixed-bottom">
                    <div className="menu-area menu-sticky footer-bg">
                        <div className="container">
                            <div className="p21 copyright-text white text-center">
                            Minutly.com 2024. All Rights Reserved
                            </div>
                        </div>
                    </div>

                </header> 
            </div>
        </div>

    );
}