import React, { useEffect } from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import ScopeTemplate from "../../assets/templates/MTY_SCOPE_STATEMENT_V1.docx";
import ProjectTemplate from "../../assets/templates/MTY_PROJECT_CHARTER_V1.docx";
import { baseUrl } from "../../services/generator.services";
import axios from "axios";
import { disableSpinner, enableSpinner, store } from "../../_store";
import { useDispatch } from "react-redux";

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

const TemplateGenerator = ({ templateType, templateName, generate, template }) => {

    const dispatch = useDispatch();
    const showSpinner = () => dispatch(enableSpinner());
    const hideSpinner = () => dispatch(disableSpinner());

    const templateMap = {
        "MTY_PROJECT_CHARTER_V1": ProjectTemplate,
        "MTY_SCOPE_STATEMENT_V1": ScopeTemplate
    };

    useEffect(() => {
        if (generate && Object.keys(template)?.length > 0) {
            generateDocument(template);
        }
    }, [generate, template]);

    const generateDocument = (template) => {
        loadFile(templateMap[templateName], function(error, content) {
            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            let doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true
            });
            doc.setData(template);
            try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
            } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                    if (value instanceof Error) {
                        return Object.getOwnPropertyNames(value).reduce(function(
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                            {});
                    }
                    return value;
                }

                console.log(JSON.stringify({ error: error }, replaceErrors));

                if (error.properties && error.properties.errors instanceof Array) {
                    const errorMessages = error.properties.errors
                        .map(function(error) {
                            return error.properties.explanation;
                        })
                        .join("\n");
                    console.log("errorMessages", errorMessages);
                    // errorMessages is a humanly readable message looking like this :
                    // 'The tag beginning with "foobar" is unopened'
                }
                throw error;
            }

            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            }); //Output the document using Data-URI

            setTimeout(() => {
                uploadToS3(template?.user_document_id, blob, templateType?.split(' ')?.join('_'));
            }, 100);
        });
    };

    const uploadToS3 = async (user_document_id, blob, filename) => {
        try {
            const token = store.getState().auth.value?.bearerToken;
            const formData = new FormData();
            formData.append("file", blob, filename);
            showSpinner();
            // Send the file to the server using Axios
            const response = await axios.post(`${baseUrl}/generator/${user_document_id}/saveGeneratedDocument`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json"
                }
            });
            if (response?.data) {
                saveAs(blob, response?.data);
                hideSpinner();
            }
            // Handle successful response if needed
        } catch (error) {
            console.error("Error uploading file:", error);
            hideSpinner();
            alert("Error uploading file. Please try again.");
        }
    };

    return (
        <div className="p-2">
        </div>
    );
};

export default TemplateGenerator;