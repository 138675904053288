import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Footer, Header } from "../../components/common";

export { Welcome };

function Welcome() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header />

            <div className="container ">

                {/* // Top Slider */}
                <div className="topheader-background">
                    <div className="col-md-12 display-flex mt-45">
                        <div className="col-md-6   ">
                            <div className="content-part pad-45-0 md-pt-20 ">
                                <div>
                                    <h1 className="font-normal line-height-66 blue-color">Create High Quality,
                                        Professional
                                        Documents in Minutes
                                    </h1>
                                </div>
                                <h4 className="font-normal line-height-30">
                                    Standardize your IT documentation process and establish best practices using our AI
                                    document generator. Create everything from management plans to support documents in
                                    just
                                    a few clicks.
                                </h4>
                                <div className="md-text-center md-mt-70 sm-mt-70">
                            <span className="home-span-btn">
                                <a className=" pointer">
                                <Link to="../register"
                                      className="white pointer login-signup bnr-btn mr-20 bnr-btn-ln blue-btn md-m-0">Sign-Up for Free</Link>
                                </a>
                            </span>

                                    <span className="pointer home-span-btn">
                            <a rel="noopener noreferrer " target="_blank" href="https://minutly.com/demo">
                            <span className="login-signup bnr-btn req-demo bnr-btn-ln white">
                                Request for demo 
                            </span>
                            </a>
                            </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 md-mt-60 md-mb-60">
                            <div className="slider-video welcome-ts-bg">
                            </div>
                        </div>
                    </div>
                </div>
                {/* // Document Type */}
                <div className="rs-services style7 style1 pt-55 pb-55 md-pt-30 md-pb-30 sm-pb-30">
                    <div className="container coe">
                        <div className="sec-title4 text-center mb-60">
                            <h2 className="line-ht-40 all-section-header line-ht-unset m-10">Popular Document Types
                                supported by Minutly AI Generator</h2>
                        </div>
                        <div className="row gutter-16 ind-service-wrap">
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon1 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/project-managers.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Project Management </a></h4>
                                        <div className="desc lm-btn-desc">
                                            Complete projects successfully with our essential project documents,
                                            covering
                                            every phase from initiation to closeout
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon2 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/pm-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Product Management</a></h4>
                                        <div className="desc lm-btn-desc">
                                            Drive product success from concept to launch with our comprehensive product
                                            management documents
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon3 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/code.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Software Engineering</a></h4>
                                        <div className="desc lm-btn-desc">
                                            Enhance your development lifecycle with our software engineering documents,
                                            ensuring quality every step of the way
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon4 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/qm-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Quality Management </a></h4>
                                        <div className="desc lm-btn-desc">
                                            Strategize, plan, and establish high standards and consistency with our
                                            detailed
                                            quality management documents
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon5 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/am-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Agile Management</a></h4>
                                        <div className="desc lm-btn-desc">
                                            From roadmaps, and backlogs to sprints, facilitating effective planning,
                                            tracking, and execution of Agile projects
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon6 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/am-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Agile Management</a></h4>
                                        <div className="desc lm-btn-desc">
                                            From backlogs to sprints, facilitating effective planning, tracking, and
                                            execution of Agile projects
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon7 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/devops.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">User Documents</a></h4>
                                        <div className="desc lm-btn-desc">
                                            Strategize, plan, and establish high standards and consistency with our
                                            detailed
                                            quality management documents

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon8 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/sd-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Support Documents</a></h4>
                                        <div className="desc lm-btn-desc">
                                            Craft highly convertible, SEO-friendly websites, landing pages, and other
                                            web
                                            content
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6 md-mb-16 p-r-l-15 mt-15 mb-15 mt-15">
                                <div className="services-wraps jog">
                                    <div className="dt-icon9 wum-img">
                                        {/* <Image className="back-blendy"  unoptimized={true} loading="eager" width="42" height="42" src="/assets/images/pro/wc-100-100.png" alt="image" /> */}
                                    </div>
                                    <div className="content-part">
                                        <h4 className="title"><a className="black">Website Content</a></h4>
                                        <div className="desc lm-btn-desc">
                                            Craft highly convertible, SEO-friendly websites, landing pages, and other
                                            web
                                            content
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="md-text-center md-mt-70 sm-mt-70 text-center mt-35">
                              <span className="home-span-btn">
                                  <a className=" bnr-btn-ln white md-m-0 blue-btn pointer" rel="noopener noreferrer"
                                     target="_blank" href="https://pro.minutly.com/register">
                                  Get started for free
                                  </a>
                              </span>
                        </div>
                    </div>
                </div>
                {/* // How It Works */}
                <div className="text-center rs-partner modify6 pt-55 pb-55 md-pt-30 md-pb-30">
                    <div className="section-bg-blue pt-80 pb-80">
                        <div className="sec-title4 text-center mb-60 md-mb-80 sm-mb-41">
                            <div className="sub-title new" id="hireteams">How it works?</div>
                            <h2 className="part-section-header-new line-ht-unset">Amplify your ideas with our AI-powered
                                generator</h2>
                        </div>
                        <div className="nav-item-text nt-text lt-new-clr lt-new-clr-resp">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="display-flex col-md-12 sm-md-prt padding-0">
                                    <div className="tab-nav-sec col-md-4">
                                        <Nav variant="pills" className="flex-column hiwn">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    <div className="col-md-12 display-flex padding-0">
                                                        <div className="col-md-2 padding-0  sm-pb-20 md-pb-20">
                                                            <div className="target-icon"></div>
                                                        </div>
                                                        <div className="col-md-10  text-left">
                                                            <h4 className="mb-6">Select your document </h4>
                                                            <p>Log in, and select your document from our comprehensive
                                                                document library
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    <div className="col-md-12 display-flex padding-0">
                                                        <div className="col-md-2 padding-0 sm-pb-20 md-pb-20">
                                                            <div className="target-icon"></div>
                                                        </div>
                                                        <div className="col-md-10  text-left">
                                                            <h4 className="mb-6">Input your context</h4>
                                                            <p>Our AI-powered generator will collect personalized,
                                                                document-specific responses from you
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">
                                                    <div className="col-md-12 display-flex padding-0">
                                                        <div className="col-md-2 padding-0 sm-pb-20 md-pb-20">
                                                            <div className="target-icon"></div>
                                                        </div>
                                                        <div className="col-md-10  text-left">
                                                            <h4 className="mb-6">Download your document</h4>
                                                            <p>Download your personalized AI-generated documents. You
                                                                can
                                                                edit or regenerate the document when you need it. </p>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="tab-cont-sec-new col-md-8">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className="col-md-12 display-flexs center-video">
                                                    <div className="how-tabs-img"></div>
                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="second">
                                                <div className="col-md-12 display-flexs center-video">
                                                    <div className="how-tabs-img"></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <div className="col-md-12 display-flexs center-video">
                                                    <div className="how-tabs-img"></div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>

                                    </div>
                                </div>

                            </Tab.Container>

                        </div>


                    </div>
                </div>
                {/* // FAQ */}
                <div className="rs-services style7 style1 pt-55 pb-55 md-pt-30 md-pb-30 sm-pb-30">
                    <div className="container coe">
                        <div className="sec-title4 text-center mb-60">
                            <h2 className="line-ht-40 all-section-header line-ht-unset">Frequently Asked Questions
                                (FAQs)</h2>
                        </div>
                        <div className="faq col-lg-12 pr-55 md-pr-15 md-mb-30">
                            <div className="bbs">
                                <div className="qa" onClick={() => toggleAccordion(1)}>
                                    Standardization for Efficiency?
                                    <i className={`fa fa-caret-down accord-icon ${activeIndex === 1 ? "rotate" : ""}`}
                                       aria-hidden="true"></i>
                                </div>
                                {activeIndex === 1 && (
                                    <div className="content">
                                        <p className="word-wraping-faq">
                                            Productizing services allows for the creation of standardized staffing
                                            solutions
                                            that can be efficiently replicated and scaled. This streamlines the staffing
                                            process, reducing the time and resources needed to tailor solutions for each
                                            individual client.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bbs">
                                <div className="qa" onClick={() => toggleAccordion(2)}>
                                    Clear and Predictable Pricing Models?
                                    <i className={`fa fa-caret-down accord-icon ${activeIndex === 2 ? "rotate" : ""}`}
                                       aria-hidden="true"></i>
                                </div>
                                {activeIndex === 2 && (
                                    <div className="content">
                                        <p className="word-wraping-faq">
                                            With productized services, you can offer clear, fixed pricing structures.
                                            This
                                            transparency is appealing to clients as it simplifies budgeting and
                                            financial
                                            planning, and it also provides your agency with predictable revenue streams.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bbs">
                                <div className="qa" onClick={() => toggleAccordion(3)}>
                                    Easier Market Penetration and Scalability?
                                    <i className={`fa fa-caret-down accord-icon ${activeIndex === 3 ? "rotate" : ""}`}
                                       aria-hidden="true"></i>
                                </div>
                                {activeIndex === 3 && (
                                    <div className="content">
                                        <p className="word-wraping-faq">
                                            Productized services can be more easily marketed and sold to a broader
                                            audience.
                                            They allow for clearer marketing messages and can be tailored to meet the
                                            needs
                                            of different market segments, helping to expand your client base and scale
                                            your
                                            business.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="bbs">
                                <div className="qa" onClick={() => toggleAccordion(4)}>
                                    Improved Customer Experience?
                                    <i className={`fa fa-caret-down accord-icon ${activeIndex === 4 ? "rotate" : ""}`}
                                       aria-hidden="true"></i>
                                </div>
                                {activeIndex === 4 && (
                                    <div className="content">
                                        <p className="word-wraping-faq">
                                            By offering predefined service packages, clients can easily understand what
                                            they
                                            are getting without the need for lengthy consultations or negotiations. This
                                            simplifies the buying process, leading to a better customer experience and
                                            potentially higher client satisfaction and loyalty.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
