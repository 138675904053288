import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions, disableSpinner, enableSpinner } from "../../_store";
import { getAllDocuments, getDocumentById } from "../../services/generator.services";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import GeneratorModal from "../Modal/GeneratorModal";
import { useParams } from "react-router-dom";


const Document = () => {
    const { documentkey } = useParams();
    const [document, setDocument] = useState([]);
    const [docQuestions, setDocQuestions] = useState([]);
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const showSpinner = () => dispatch(enableSpinner());
    const hideSpinner = () => dispatch(disableSpinner());

    useEffect(() => {
        console.log({ documentkey });
        showSpinner();
        getDocumentById(documentkey).then(docs => {
            const { document: doc, questions } = docs;
            console.log({ doc, questions });
            setDocument(doc);
            setDocQuestions(questions);
            hideSpinner();
            if(questions?.length > 0) {
                setShow(true);
            } else {
                dispatch(alertActions.error({ message: 'Document questions not available', showAfterRedirect: false }));
            }
        }).catch(err => {
            console.log(err);
            hideSpinner();
        });
    }, []);

    return (

        <div className="container mt-60">
            <div className="pt-20">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">AI Documents</Breadcrumb.Item>
                    <Breadcrumb.Item active>Project Management</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* //// CARD SECTION //// */}

            <div className="pg-hd mb-10 col-md-12 display-flex pt-20">
                <div className="h3d col-md-9">
                    Project Management
                </div>
                <div className="search col-md-3">
                    <input className="search-txt" type="text" name="" placeholder="Type to search" />
                    <a className="search-btn" href="#">
                        <i className="fa fa-search"></i>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {Object.keys(document)?.length > 0 ?
                        <div key={documentkey} className="col-md-3 newcars">
                            {/* <div className="newcars-title">
                            <h5 className="newcars-title-header">{document?.name}</h5>
                        </div> */}
                            <div className="background">
                                <div className="background-layer">
                                    <div className="background-layer-text ">{document?.name}</div>
                                </div>
                            </div>
                            <div className="mt-20 f-16 fw-700">
                                {document?.name}
                            </div>
                            <div className="newcars-counts mt-10 word-wraping">
                                {document?.description}
                            </div>
                            <div className="pay-later-empty"></div>
                            <div className="col-md-12 display-flex flew-row-unset">
                                <div className="col-md-7 padding-0">
                                </div>
                                <div className="col-md-5 padding-0 text-end">
                                    <Button className="span-btn" onClick={() => setShow(true)}> Generate </Button>
                                </div>
                            </div>
                        </div> : null}
                </div>
            </div>
            {/* /// MODEL SECTION ///// */}
            {docQuestions?.length > 0 ?
                <GeneratorModal mShow={show} questions={docQuestions} selectedDocument={document}
                                hideModal={setShow}></GeneratorModal> : null}
        </div>
    );
};


export default Document;