import React from 'react';
import { Routes, Route, Outlet } from "react-router-dom";

import { List, AddEdit } from './index';

export { UsersLayout };

function UsersLayout() {
    return (
        <div className="p-4">
            <div className="">
                <Outlet></Outlet>
            </div>
        </div>
    );
}
