import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "./_store";
import { App } from "./App";
import "./index.css";
// import "./css/bootstrap.min.css";
// import "./css/font-awesome.min.css";
import "./styles/spacing.css";
import "./styles/header.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/login.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./styles/register.css";
import "./styles/card.css";
import "./styles/model.css";
import "./styles/table.css";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";
import Spinner from "./components/common/spinner/Spinner";
import CommonSpinner from "./components/common/spinner/Spinner";
// setup fake backend
// import { fakeBackend } from "./_helpers";
// fakeBackend();
import "./styles/style.css";
import "./styles/welcome.css";
import "./styles/alert.css"
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    </React.StrictMode>
);
