import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import signin from "../../assets/images/login-image.jpg";
import { authActions, alertActions } from '../../_store';

export { Login };


function Login() {
    const dispatch = useDispatch();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password })).then(response => {
            // console.log("RESPONSE :" + JSON.stringify(response));
            if (response.payload) {
                // dispatch(alertActions.success({ message: 'Login Successfully.', showAfterRedirect: true }));
            } else {
                dispatch(alertActions.error({ message: 'Login failed! Please enter valid username and password.', showAfterRedirect: true }));
            }
        }).catch(error => {
            dispatch(alertActions.error({ message: error.message || 'An error occurred. Please try again.', showAfterRedirect: true }));
        });
        
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* //// LOGIN SECTION //// */}
            <div className="card-container">
                <div className="col-md-12 signin-content">
                    <div className="col-md-6">
                        <div className="signin-image">
                            <figure><img className="img-trans" src={signin} alt="sing up image" /></figure>
                            <Link to="../register" className="signup-image-link">Create Account</Link>
                        </div>
                    </div>
                    <div className="col-md-6 signin-form">
                        <h2 className="form-title mb-33">Sign in</h2>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="lbl"> Email Address </label>
                                    <input placeholder="Enter Email" name="username"
                                           type="text" {...register("username")}
                                           className={`form-control ${errors.username ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.username?.message}</div>
                                </div>
                                <div className="form-group">
                                    <label className="lbl"> Password</label>
                                    <input placeholder="Enter Password" name="password"
                                           type="password" {...register("password")}
                                           className={`form-control ${errors.password ? "is-invalid" : ""}`} />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>

                                <div className="mt-50">
                        <span>
                        <button disabled={isSubmitting} className="btn btn-primary">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Login
                        </button>
                        </span>
                                    <span className="float-right">
                        <Link to="../forget" className="forget-link">Forget password</Link>
                        </span>
                                </div>

                            </form>
                        </div>
                        <div className="mt-67 text-center">
                            <span className="mr-25 social-hd">Or sign in with</span>
                            <span className="mr-15"><i className="fa fa-google google" aria-hidden="true"></i></span>
                            <span className="mr-15"><i className="fa fa-linkedin linkedin"
                                                       aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
