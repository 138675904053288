import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';
import minutlylogo from "../../assets/images/minutly-logo.png";
import { Link } from 'react-router-dom';
import nouserlogo from "../../assets/images/no-user-logo.jpg"
import Dropdown from 'react-bootstrap/Dropdown';

export { Nav };

function Nav() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!auth) return null;
    
    return (
        // <nav className="navbar navbar-expand menu-sticky px-3">
        //     <div className="navbar-nav">
        //     <img className="minutly-logo" width="100" height="22"  src={minutlylogo} alt="MINUTLY" />
        //         <NavLink to="/" className="nav-item nav-link">Home</NavLink>
        //         <NavLink to="/users" className="nav-item nav-link">Users</NavLink>
        //         <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
        //     </div>
        // </nav>

        <div className="">
            <div className="full-width-header md-main-header">
                <header id="rs-header" className="rs-header fixed-top">
                    <div className="menu-area menu-sticky">
                        <div className="container">
                            <div className="display-flex">
                                <div className="col-lg-2">
                                    <div className="logo-area">
                                        <a>
                                        <img className="minutly-logo" width="100" height="22"  src={minutlylogo} alt="MINUTLY" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-10 text-right">
                                    <div className="rs-menu-area">
                                        <div className="main-menu">
                                            <nav className="rs-menu">
                                                <ul className="nav-menu" >
                                                <li>
                                                    <img className="no-user-logo" width="50" height="50"  src={nouserlogo} alt="MINUTLY" />
                                                    </li>
                                                    <li className='u-n'>
                                                    <Dropdown className='u-n'>
                                                        <Dropdown.Toggle id="dropdown-custom-components">
                                                        Hi {auth?.firstName}!
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu >
                                                        <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" onClick={logout}>Logout</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </li>
                                                </ul>
                                            </nav>

                                            <div className="expand-btn-inner">
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </header> 
                <div className="form-inline pt-3">
                </div>                    
            </div>
        </div>

    );
}