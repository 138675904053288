const PathConstants = {
    HOME: "home",
    Dashboard: "dashboard",
    USER: "users/*",
    ACCOUNT: "account/*",
    ADD: "add",
    EDIT: "edit/:id",
    LOGIN: 'login',
    REGISTER: 'register',
    FORGET: 'forget',
    RESET_PASSWORD: 'resetpassword',
    VIEW_DOCUMENT: 'document/:documentName',
    WELCOME: 'welcome',
};

export default PathConstants;
