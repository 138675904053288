import React from "react";

const Spinner = () => {
    return (
        <div>
            <div
                style={{
                    visibility: "visible",
                    position: "absolute",
                    zIndex: "1001",
                    background: "rgba(0, 0, 0, 0.227)",
                    transition: "opacity 0.2s ease 0s",
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}
            >
                <span>
            <svg
                style={Styles.spinner}
                viewBox="0 0 42 42">
              <g
                  fill="none"
                  transform="translate(3 3)"
                  strokeWidth="3">

                <circle
                    style={Styles.circle}
                    cx="18"
                    cy="18"
                    r="18" />

                <path
                    style={Styles.segment}
                    d="M36 18c0-9.94-8.06-18-18-18"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
              </g>
            </svg>
          </span>
            </div>
        </div>);
};

export default Spinner;

const Styles = {
    spinner: {
        animation: "spinner-animation 900ms linear infinite",
        display: "inline-block",
        width: "100px",
        height: "100px",
        marginTop: "20%",
        marginLeft: "50%",
    },

    circle: {
        stroke: "#979797",
        strokeOpacity: "0.25"
    },

    segment: {
        stroke: "steelblue"
    }
};