import { createSlice } from "@reduxjs/toolkit";
import { loader } from "react-global-loader";
export const spinnerSlice = createSlice({
    name: "spinner",
    initialState: {
        show: false
    },
    reducers: {
        enableSpinner: (state) => {
            loader.show();
            state.show = true;
        },
        disableSpinner: (state) => {
            loader.hide();
            state.show = false;
        }
    }
});

export const { enableSpinner, disableSpinner } = spinnerSlice.actions;

export default spinnerSlice.reducer;
