import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { alertActions } from "../_store";
import { fetchWrapper, history } from "../_helpers";
import { loader } from "react-global-loader";

// create slice

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        value: JSON.parse(localStorage.getItem("auth"))
    };
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    // console.log("Base URL: " + process.env.REACT_APP_API_URL);
    const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;

    return {
        login: login(),
        register: register(),
        logout: logout(),
        forget: forget(),
    };


    // function login() {
    //     return createAsyncThunk(
    //         `${name}/login`,
    //         async function({ username, password }, { dispatch }) {
    //             dispatch(alertActions.clear());
    //             try {
    //                 loader.show();
    //                 const payload = { email: username, password: password };
    //                 const user = await fetchWrapper.post(`${baseUrl}/login`, payload);
    
    //                 if (user) {
    //                     loader.hide();
    //                     dispatch(authActions.setAuth(user));
    //                     console.log(user)
    //                     localStorage.setItem("auth", JSON.stringify(user));
    //                     const { from } = history.location.state || { from: { pathname: "/" } };
    //                     history.navigate(from);
    //                 }
    //                 loader.hide();
    //                 return user; // Return user for further processing if needed
    //             } catch (error) {
    //                 // Dispatch a more serializable error
    //                 dispatch(alertActions.error({
    //                     message: error.message || 'An error occurred',
    //                     // You can add more error details here if needed
    //                 }));
    //                 loader.hide();
    //             }
    //         }
    //     );
    // }
    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function({ username, password }, { dispatch }) {
                dispatch(alertActions.clear());
    
                try {
                    loader.show(); // Show loader at the start
                    const payload = { email: username, password: password };
                    const user = await fetchWrapper.post(`${baseUrl}/login`, payload);
    
                    if (user) {
                        dispatch(authActions.setAuth(user));
                        console.log(user);
                        localStorage.setItem("auth", JSON.stringify(user));
    
                        const { from } = history.location.state || { from: { pathname: "/" } };
                        history.navigate(from);
                    }
    
                    return user; // Return user for further processing if needed
                } catch (error) {
                    dispatch(alertActions.error({
                        message: error.message || 'An error occurred',
                    }));
                } finally {
                    loader.hide(); // Ensure loader is hidden in both success and error cases
                }
            }
        );
    }
    


    // function login() {
    //     return createAsyncThunk(
    //         `${name}/login`,
    //         async function({ username, password }, { dispatch }) {
    //             dispatch(alertActions.clear());
    //             try {
    //                 loader.show();
    //                 let payload = { email: username, password: password };
    //                 const user = await fetchWrapper.post(`${baseUrl}/login`, payload);
    //                 if (user) {
    //                     loader.hide();
    //                 }
    //                 dispatch(authActions.setAuth(user));

    //                 localStorage.setItem("auth", JSON.stringify(user));

    //                 const { from } = history.location.state || { from: { pathname: "/" } };
    //                 history.navigate(from);
    //             } catch (error) {
    //                 dispatch(alertActions.error(error));
    //                 loader.hide();
    //             }
    //         }
    //     );
    // }

    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async function (data, { getState, dispatch }) {
                loader.show();
                let payload = { firstName: data.firstName, lastName: data.lastName, password: data.password, username: data.username, email: data.username, };
                const user = await fetchWrapper.post(`${baseUrl}/register`, payload)
                if (user) {
                    loader.hide();
                }
            }
        );
    }

    function forget() {
        return createAsyncThunk(
            `${name}/forget`,
            async function({ username}, { dispatch }) {
                loader.show();
                try {
                    const user = await fetchWrapper.post(`${baseUrl}/resetPassword?email=` +username, null)
                    if (user) {
                        loader.hide();
                        return user;
                    }
                } catch (error) {
                    dispatch(alertActions.error(error));
                    loader.hide();
                }
            }
        );
    }
    

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function(arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem("auth");
                history.navigate("/account/login");
            }
        );
    }
}