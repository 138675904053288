import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';
import minutlylogo from "../../assets/images/minutly-logo.png";
import { Link } from 'react-router-dom';
import nouserlogo from "../../pages/account/Login"
import Dropdown from 'react-bootstrap/Dropdown';

export { Header };

function Header() {

    return (

        <div className="">
            <div className="full-width-header md-main-header">
                <header id="rs-header" className="rs-header fixed-top">
                    <div className="menu-area menu-sticky">
                        <div className="container">
                            <div className="display-flex">
                                <div className="col-lg-2">
                                    <div className="logo-area">
                                        <a>
                                            <img className="minutly-logo" width="100" height="22" src={minutlylogo} alt="MINUTLY" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-10 text-right lh-52">
                                    <div className="rs-menu-area">
                                        <div className="main-menu">

                                            <span className="home-span-btn" >
                                                <Link to="/account/Login" className="login-signup  ml-10 mr-10">
                                                            <i className="fa fa-unlock-alt pr-7 mr-1"></i>
                                                            Login
                                                        </Link>
                                            </span>
                                            <span className="home-span-btn">
                                                <Link to="/account/Register"  className="login-signup  ml-10 mr-10">
                                                            <i className="fa fa-user pr-7 mr-1"></i>
                                                            Register
                                                        </Link>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </header>
                <div className="form-inline pt-3">
                </div>
            </div>
        </div>

    );
}