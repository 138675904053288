import { Outlet, useLocation, useNavigate } from "react-router-dom";

import React, { Suspense } from "react";
import { Alert, Nav } from "../common";
import Spinner from "../common/spinner/Spinner";
import { LoaderContainer } from "react-global-loader";
import { useSelector } from "react-redux";

export default function Layout() {
    history.navigate = useNavigate();
    history.location = useLocation();
    return (
        <>
            <Nav />
            <Alert />
            <LoaderContainer>
                {/*<DefaultSpinner />*/}
                <Spinner />
            </LoaderContainer>
            <main>
                <Suspense fallback={<Spinner />}>
                    <Outlet />
                </Suspense>
            </main>
        </>
    );
}
