import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import signin from "../../assets/images/login-image.jpg"
import { history } from '../../_helpers';
import { userActions, alertActions, authActions } from '../../_store';

export { Register };

function Register() {
    const dispatch = useDispatch();
    // form validation rules 
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        username: Yup.string()
            .required('Username is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmpassword: Yup.string()
            .required('Confirm Password is required')
            .min(6, 'Confirm Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onSubmit(data) {

        dispatch(alertActions.clear());
        try {
            await dispatch(authActions.register(data)).unwrap();

            // redirect to login page and display success alert
            // history.navigate('/account/login');
            dispatch(alertActions.success({ message: 'Registration successful', showAfterRedirect: true }));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        // <div className="card m-3">
        //     <h4 className="card-header">Register</h4>
        //     <div className="card-body">
        //         <form onSubmit={handleSubmit(onSubmit)}>
        //             <div className="mb-3">
        //                 <label className="form-label">First Name</label>
        //                 <input name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
        //                 <div className="invalid-feedback">{errors.firstName?.message}</div>
        //             </div>
        //             <div className="mb-3">
        //                 <label className="form-label">Last Name</label>
        //                 <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
        //                 <div className="invalid-feedback">{errors.lastName?.message}</div>
        //             </div>
        //             <div className="mb-3">
        //                 <label className="form-label">Username</label>
        //                 <input name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
        //                 <div className="invalid-feedback">{errors.username?.message}</div>
        //             </div>
        //             <div className="mb-3">
        //                 <label className="form-label">Password</label>
        //                 <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
        //                 <div className="invalid-feedback">{errors.password?.message}</div>
        //             </div>
        //             <button disabled={isSubmitting} className="btn btn-primary">
        //                 {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
        //                 Register
        //             </button>
        //             <Link to="../login" className="btn btn-link">Cancel</Link>
        //         </form>
        //     </div>
        // </div>
        <div className=" card-container">
            <div className="col-md-12 signin-content">
            <div className="col-md-6 signin-form">
            <h2 className="form-title mb-33">Sign up</h2>
            <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <input placeholder='Enter First Name' name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input placeholder='Enter Last Name' name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.lastName?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email Address</label>
                        <input placeholder='Enter Email' name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input placeholder='Password' name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input placeholder='Confirm Password' name="confirmpassword" type="password" {...register('confirmpassword')} className={`form-control ${errors.confirmpassword ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                    <div className='mt-40'>
                    <button disabled={isSubmitting} className="btn btn-primary">
                        {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                        Register
                    </button>
                    <Link to="../login" className="btn btn-link">Cancel</Link>
                    </div>
                </form>
            </div>
            <div className='mt-30 text-center'>
                <span className='mr-25 social-hd'>Or sing up with</span>
                <span  className='mr-15'><i className="fa fa-google google" aria-hidden="true"></i></span>
                <span className='mr-15'><i className="fa fa-linkedin linkedin" aria-hidden="true"></i></span>
            </div>
            </div>
            <div className="col-md-6 mt-70">
            <div className="signin-image">
                        <figure><img  className='img-trans' src={signin} alt="sing up image" /></figure>
                        <Link to="../login" className="signup-image-link">I am already member</Link>
                    </div>
            </div>
            </div>
    </div>
    )
}
