import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import minutlylogo from "../../assets/images/minutly-logo.png";
import Button from "react-bootstrap/Button";
import { disableSpinner, enableSpinner, store } from "../../_store";
import { saveGenerateTemplate, updateGenerateTemplate } from "../../services/generator.services";
import { Col, Form, Row } from "react-bootstrap";
import TemplateGenerator from "../TemplateGenerator/TemplateGenerator";
import { formatTemplateResponse } from "../../utils/common";
import { useDispatch } from "react-redux";

const GeneratorModal = ({ questions, selectedDocument, mShow, hideModal, documentValues }) => {
    const [generate, setGenerate] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [sectionMapFields, setSectionMapFields] = useState([]);
    const [formData, setFormData] = useState({});
    const [formSectionData, setFormSectionData] = useState({});
    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const userId = store.getState().auth.value?.userId;

    const handleClose = () => hideModal(false);
    const showSpinner = () => dispatch(enableSpinner());
    const hideSpinner = () => dispatch(disableSpinner());

    useEffect(() => {
        if (questions?.length > 0) {
            setFormData({});
            setFormFields([]);
            setGenerate(false);
            setValidated(false);
            setFormFields(questions?.map(question => ({
                label: question["question_text"],
                name: question["section_name"],
                type: question["question_type"],
                placeholder: question["placeholder"],
                description: !question["mandatory_ind"] ? question["description"] : question["description"] + " *",
                required: !!question["mandatory_ind"],
                errorLabel: question["error_label"],
                validation: {
                    required: !!question["mandatory_ind"]
                },
                options: question['questionOptionsList']
            })));

            setSectionMapFields(questions?.reduce((acc, item) => {
                acc[item?.section_name] = [item?.document_section_id, item?.prompt_id];
                return acc;
            }, {}));

            if (documentValues && Object.keys(documentValues)?.length > 0) {
                setTimeout(() => {
                    const formattedFormData = formatTemplateResponse(documentValues);
                    setFormData(formattedFormData);
                    setFormSectionData(documentValues?.reduce((acc, item) => {
                        acc[item?.section_name] = item?.user_doc_section_id;
                        return acc;
                    }, {}));
                }, 100);
            }
        }
    }, [questions]);

    useEffect(() => {
        setFormData({});
        setGenerate(false);
    }, [mShow]);

    const handleChange = (e) => {
        console.log({e})
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setTimeout(() => {
            console.log({name, value, formData})
        }, 500)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formRef?.current?.checkValidity()) {
            showSpinner();
            const generatorObj = generatePayload();
            if (selectedDocument?.user_document_id) {
                updateGenerateTemplate(selectedDocument?.user_document_id, generatorObj).then(res => {
                    const formattedFormData = formatTemplateResponse(res);
                    setFormattedData({ ...formattedFormData, user_document_id: selectedDocument?.user_document_id });
                    setGenerate(true);
                    handleClose();
                    hideSpinner();
                }, err => {
                    console.log(err);
                    hideSpinner();
                });
            } else {
                saveGenerateTemplate(generatorObj).then(res => {
                    const formattedFormData = formatTemplateResponse(res);
                    setFormattedData({ ...formattedFormData, user_document_id: res?.[0]?.user_document_id });
                    setGenerate(true);
                    handleClose();
                    hideSpinner();
                }, err => {
                    console.log(err);
                    hideSpinner();
                });
                console.log("Form data submitted:", formData);
            }
        } else {
            console.log("Form data submitted, Invalid");
        }
        setValidated(true);
    };

    const generatePayload = () => {
        let payload = Object.keys(formData).map(key => ({
            userId,
            documentSectionId: sectionMapFields[key][0],
            promptId: sectionMapFields[key][1],
            responseText: formData[key],
            documentId: selectedDocument?.document_id,
            userDocSectionId: formSectionData[key] || 0
        }));
        if (selectedDocument?.document_id === 1) {
            payload.splice(1, 0, {
                userId,
                documentSectionId: 12,
                promptId: 4,
                responseText: formData["pc_project_objective"],
                documentId: selectedDocument?.document_id,
                userDocSectionId: formSectionData["pc_exec_summary"] || 0
            });
        }
        return payload;
    };

    return (
        <div>
            <Modal show={mShow} onHide={handleClose}
                   backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDocument?.name} Generator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 display-flex">
                        <div className="col-md-3">
                            <div className="ml-lt-logo">
                                <img className="minutly-logo" width="100" height="22" src={minutlylogo} alt="MINUTLY" />
                            </div>
                            <h4 className="ml-lt-hdr">
                                {selectedDocument?.name}
                            </h4>
                            <p className="ml-lt-p">
                                {selectedDocument?.description}
                            </p>
                        </div>
                        <div className="col-md-9 body-scroll">
                            <div className="container">
                                <Form ref={formRef} noValidate validated={validated}>
                                    {formFields.map((field, index) => (
                                        <Form.Group as={Row} key={index} style={{ marginBottom: 15 }}>
                                            <Form.Group as={Col} controlId={field.name}>
                                                <Form.Label>{field.label}</Form.Label>
                                                <div className="sml-label">{field.description}</div>
                                                {field.type === "select" && (
                                                    <Form.Control
                                                        as="select"
                                                        name={field.name}
                                                        value={formData[field.name] || ""}
                                                        onChange={handleChange}
                                                        required={field.required}
                                                        isInvalid={!formData[field.name]?.length}
                                                    >
                                                        <option value="">Select {field.label}</option>
                                                        {field?.options?.map(option => (
                                                            <option key={option?.option_value}
                                                                    value={option?.option_value}>
                                                                {option?.option_text}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                )}
                                                {field.type === "checkbox" && (
                                                    <div>
                                                        {field.options.map((option, optionIndex) => (
                                                            <Form.Check
                                                                key={optionIndex}
                                                                type={field?.type}
                                                                inline
                                                                id={`${field.name}_${optionIndex}`}
                                                                label={option}
                                                                name={field.name}
                                                                required={field.required}
                                                                checked={formData[field.name]?.includes(option) || false}
                                                                onChange={(e) => {
                                                                    const selectedOptions = e.target.checked
                                                                        ? [...(formData[field.name] || []), option]
                                                                        : (formData[field.name] || []).filter((item) => item !== option);
                                                                    setFormData({
                                                                        ...formData,
                                                                        [field.name]: selectedOptions
                                                                    });
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                                {field.type === "radio" && (
                                                    <div>
                                                        {field.options.map((option, optionIndex) => (
                                                            <Form.Check
                                                                key={optionIndex}
                                                                type="radio"
                                                                inline
                                                                id={`${field.name}_${optionIndex}`}
                                                                label={option}
                                                                required={field.required}
                                                                name={field.name}
                                                                value={option}
                                                                // checked={formData[field.name] === option}
                                                                onChange={(e) => {
                                                                    console.log({ e });
                                                                    setFormData({
                                                                        ...formData,
                                                                        [field.name]: e.target.value
                                                                    });
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                                {field.type === "textarea" && (
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name={field.name}
                                                        value={formData[field.name] || ""}
                                                        onChange={handleChange}
                                                        required={field.required}
                                                        placeholder={field.placeholder}
                                                        isInvalid={formData[field.name]?.length == 0}
                                                    />
                                                )}
                                                {["text", "password", "email"].includes(field.type) && (
                                                    <Form.Control
                                                        type={field.type}
                                                        placeholder={field.placeholder}
                                                        name={field.name}
                                                        required={field.required}
                                                        value={formData[field.name] || ""}
                                                        onChange={handleChange}
                                                        isInvalid={formData[field.name]?.length === 0}
                                                    />
                                                )}
                                                <Form.Control.Feedback type="invalid">
                                                    {field.errorLabel} is required.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Group>
                                    ))}
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Link to="../regenerate"> */}
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    {/* </Link> */}
                    <Button variant="primary" onClick={(e) => handleSubmit(e)}>
                        <i className="fa fa-download"></i> Generate
                    </Button>
                </Modal.Footer>
            </Modal>
            <TemplateGenerator templateType={selectedDocument?.name} templateName={selectedDocument?.template_name}
                               generate={generate}
                               template={formattedData}></TemplateGenerator>
        </div>
    );
};

export default GeneratorModal;