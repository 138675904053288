import { fetchWrapper } from "../_helpers";

export const baseUrl = process?.env?.REACT_APP_API_URL;
export const getGenerateTemplate = async(url , payload) => {
    return await fetchWrapper.post(`${baseUrl}/generator/${url}`, payload);
}

export const getAllDocuments = async() => {
    return await fetchWrapper.get(`${baseUrl}/generator/allDocuments`);
}

export const getAllUserDocuments = async() => {
    return await fetchWrapper.get(`${baseUrl}/generator/allUserDocuments`);
}

export const getDocumentById = async(documentToken) => {
    return await fetchWrapper.get(`${baseUrl}/generator/${documentToken}/document`);
}

export const saveGenerateTemplate = async(payload) => {
    return await fetchWrapper.post(`${baseUrl}/generator/saveGenerateTemplate`, payload);
}

export const saveGeneratedDocument = async(user_document_id, payload) => {
    console.log({ payload });
    return await fetchWrapper.filePost(`${baseUrl}/generator/${user_document_id}/saveGeneratedDocument`, payload);
}

export const updateGenerateTemplate = async(user_document_id, payload) => {
    return await fetchWrapper.put(`${baseUrl}/generator/${user_document_id}/updateGenerateTemplate`, payload);
}

