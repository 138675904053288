import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const DynamicForm = ({questions, answers}) => {
    const [formFields, setFormFields] = useState([])
    const [formData, setFormData] = useState({});

    useEffect(() => {
        setFormFields(questions.map(question => ({
            label: question["question_text"],
            name: question["question_name"],
            type: question["question_type"],
            placeholder: question["description"],
            validation: {
                required: !!question['mandatory_ind']
            }
        })))
    }, [questions])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ e });
        // Perform form submission logic here
        console.log("Form data submitted:", formData);
    };

    return (
        <div className="container" style={{ marginTop: 75 }}>
            <Form onSubmit={handleSubmit}>
                {formFields.map((field, index) => (
                    <Form.Group as={Row} key={index} style={{ marginBottom: 15 }}>
                        <Form.Group as={Col} controlId={field.name}>
                            <Form.Label>{field.label}</Form.Label>
                            <div className="sml-label">{field.placeholder}</div>
                            {field.type === "select" && (
                                <Form.Control
                                    as="select"
                                    name={field.name}
                                    value={formData[field.name] || ""}
                                    onChange={handleChange}
                                    isInvalid={!!formData[field.name]?.length && !field.validation?.pattern?.test(formData[field.name])}
                                >
                                    <option value="">Select {field.label}</option>
                                    {field.options.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                            )}
                            {field.type === "checkbox" && (
                                <div>
                                    {field.options.map((option, optionIndex) => (
                                        <Form.Check
                                            key={optionIndex}
                                            type={field?.type}
                                            inline
                                            id={`${field.name}_${optionIndex}`}
                                            label={option}
                                            name={field.name}
                                            checked={formData[field.name]?.includes(option) || false}
                                            onChange={(e) => {
                                                const selectedOptions = e.target.checked
                                                    ? [...(formData[field.name] || []), option]
                                                    : (formData[field.name] || []).filter((item) => item !== option);
                                                setFormData({ ...formData, [field.name]: selectedOptions });
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {field.type === "radio" && (
                                <div>
                                    {field.options.map((option, optionIndex) => (
                                        <Form.Check
                                            key={optionIndex}
                                            type="radio"
                                            inline
                                            id={`${field.name}_${optionIndex}`}
                                            label={option}
                                            name={field.name}
                                            value={option}
                                            // checked={formData[field.name] === option}
                                            onChange={(e) => {
                                                console.log({ e });
                                                setFormData({ ...formData, [field.name]: e.target.value });
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {field.type === "textarea" && (
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name={field.name}
                                    value={formData[field.name] || ""}
                                    onChange={handleChange}
                                    isInvalid={!!formData[field.name]?.length && !field.validation?.pattern?.test(formData[field.name])}
                                />
                            )}
                            {["TEXT", "password", "email"].includes(field.type) && (
                                <Form.Control
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    value={formData[field.name] || ""}
                                    onChange={handleChange}
                                    isInvalid={formData[field.name]?.length === 0}
                                />
                            )}
                            <Form.Control.Feedback type="invalid">
                                {field.label} is invalid.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Group>
                ))}
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default DynamicForm;
