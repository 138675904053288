import React, { useEffect, useState } from "react";
import crdimg from "../../assets/images/charter.jpg";
import Button from "react-bootstrap/Button";
import { getAllDocuments, getDocumentById } from "../../services/generator.services";
import { disableSpinner, enableSpinner } from "../../_store";
import { useDispatch } from "react-redux";
import GeneratorModal from "../../components/Modal/GeneratorModal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from 'react-router-dom';
export { Dashboard };


function Dashboard() {

    // const dispatch = useDispatch();
    // const [show, setShow] = useState(false);
    // const showSpinner = () => dispatch(enableSpinner());
    // const hideSpinner = () => dispatch(disableSpinner());

    // useEffect(() => {
    //     showSpinner();
    //     getAllDocuments().then(docs => {
    //         setDocuments(docs);
    //         hideSpinner();
    //     }).catch(err => {
    //         console.log(err);
    //         hideSpinner();
    //     });
    // }, []);

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const showSpinner = () => dispatch(enableSpinner());
    const hideSpinner = () => dispatch(disableSpinner());

    useEffect(() => {
        showSpinner();
        getAllDocuments()
            .then(docs => {
                setDocuments(docs);
            })
            .catch(err => {
                console.error("Error fetching documents:", err);
            })
            .finally(() => {
                hideSpinner();
            });
    }, []);
    

    return (

        <div className="container mt-60">
            {/* <div className="pt-20">
            <Breadcrumb>
                <Breadcrumb.Item href="/">AI Documents</Breadcrumb.Item>
                <Breadcrumb.Item active>Project Management</Breadcrumb.Item>
            </Breadcrumb>
            </div> */}
            {/* //// CARD SECTION //// */}

            <div className="pg-hd mb-10 col-md-12 display-flex pt-20">
                <div className="h3d col-md-9">
                Dashboard
                </div>
                <div className="search col-md-3">
                    <input className="search-txt" type="text" name="" placeholder="Type to search" />
                    <a className="search-btn" href="#">
                        <i className="fa fa-search"></i>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row" >
                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">

                    <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo fm-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Website</a>
                            </h4>
                            <p className="word-wraping">A beautifully crafted website does not just attract 
                                visitors—it engages them around the clock, driving leads and nurturing customer relationships.
                                Whether you are starting fresh or scaling up, Minutly is here to elevate your online presence. Scroll on to find the right service.
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo d-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Development</a>
                            </h4>
                            <p className="word-wraping">Explore our curated list of essential web and mobile services to transform your idea into a fully functional app
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo hfr-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">E-commerce</a>
                            </h4>
                            <p className="word-wraping">Find the professional talent and solutions for your e-commerce business. Everything you need to start, market, and sell your product or services - all in one place!
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo dm-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Digital Marketing</a>
                            </h4>
                            <p className="word-wraping">Elevate your online presence, engage your audience, and optimize conversion with our digital marketing services
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo pm-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Management</a>
                            </h4>
                            <p className="word-wraping">Effective planning yields better results, whether whether it is personal or business. Get your projects started on the right path with product, project, and agile management services
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo qat-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">QA & Testing</a>
                            </h4>
                            <p className="word-wraping">Create strategies, develop plans, and realize your quality goals with our hand crafted QA & testing services
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo c-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Cloud</a>
                            </h4>
                            <p className="word-wraping">Setup, manage, monitor, and track your cloud computing infrastructure and applications </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo a-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Analytics</a>
                            </h4>
                            <p className="word-wraping">Turn your data into valuable insights. Find services that can help you start and scale your analytics program
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo ts-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Support </a>
                            </h4>
                            <p className="word-wraping">Keep your applications running smoothly, reduce downtime, and enhance customer satisfaction
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 p-r-l-15 sm-pb-15 sm-pt-15 pt-15 pb-15">
                        <div className="box-oneplatform mh-267">
                        <div className="line"><span className="op-logo fm-logo"></span></div>
                        <div className="mh-57">
                            <h4 className="title">
                                <a className="black">Artificial Intellgence</a>
                            </h4>
                            <p className="word-wraping">Create smart applications that can learn from data, predict outcomes, and generate faster outcomes
                            </p>
                        </div>
                        <div className="pad-inh">
                        <Link to="/home">
                            <a className="fl black">Get Started <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </a>
                            </Link>                        </div>
                        </div>
                    </div>

                 </div>
            </div>
        </div>
    );
}
