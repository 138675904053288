import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Resetpassword } from "./index";
import { Footer, Header } from "../../components/common";

export { AccountLayout };

function AccountLayout() {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    if (auth) {
        return <Navigate to="/" />;
    }

    return (
        <div className="">
            <Outlet></Outlet>
        </div>
    );
}
